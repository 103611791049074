<template>
  <DynamicContent
    :title="title"
    :contentTypeId="contentTypeId"
    :contentTypePermissions="entryPermissions"
  />
</template>

<script>
import PermissionMixin from '@/mixins/PermissionMixin';
import { mapActions, mapGetters } from 'vuex';
import DynamicContent from '../dynamicContent/DynamicContent.vue';
import { appPermissions } from '@/libs/auth/permissions/appPermissions';

const beforEnterCheckPermission = (toRoute) => {
  const { id: toContentId } = toRoute.query;
  var { isCanRead } = appPermissions.dynamicContentType(toContentId);
  return {
    has: isCanRead,
    redirectTo: '/404',
  };
};

const nextRouteOnPermissionHanlder = (to, next) => {
  const { has, redirectTo } = beforEnterCheckPermission(to);
  if (has) return next();
  return next(redirectTo);
};

export default {
  components: { DynamicContent },
  mixins: [
    PermissionMixin(appPermissions.dynamicContentType, {
      name: 'contentTypePermissions',
      dynamicKey: 'contentTypeId',
      updateOnQueryChanged: true,
    }),
    PermissionMixin(appPermissions.dynamicContent, {
      name: 'dynamicContentPermissions',
    }),
  ],
  computed: {
    ...mapGetters('entriesTypeMenu', ['nameById']),
    contentTypeId() {
      const { id } = this.$route.query;
      return id;
    },
    contentTypeSlug() {
      const { slug } = this.$route.params;
      return slug;
    },
    entryPermissions() {
      const permissions = Object.keys(this.contentTypePermissions).reduce(
        (p, key) => {
          const value = this.dynamicContentPermissions[key];
          if (value == false) return { ...p, [key]: value };
          return { ...p, [key]: this.contentTypePermissions[key] };
        },
        {},
      );
      return permissions;
    },
  },
  data() {
    return {
      title: '',
    };
  },
  methods: {
    async setTitle() {
      if (!this.contentTypeId) this.title = 'المحتوى';
      else {
        const name = this.nameById(this.contentTypeId);
        this.title = name;
      }
    },
  },
  mounted() {
    this.setTitle();
  },
  updated() {
    this.setTitle();
  },
  beforeRouteEnter(to, from, next) {
    nextRouteOnPermissionHanlder(to, next);
  },
  beforeRouteUpdate(to, from, next) {
    nextRouteOnPermissionHanlder(to, next);
  },
  watch: {
    contentTypeId(newValue, oldValue) {},
  },
};
</script>

<style lang="scss" scoped></style>
